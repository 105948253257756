<template>
  <a-modal
    :visible="visible"
    :title="`編輯${modalLabel}資料`"
    :okText="`確認更新`"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :model="data"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :span="16">
          <a-form-item
            :label="`${modalLabel}編號`"
            name="member_id"
            :rules="[{ required: true, message: `請輸入${modalLabel}編號` }]"
          >
            <a-input v-model:value="data.member_id" disabled size="large" />
          </a-form-item>
        </a-col>
      </a-row>
      
      <a-row>
        <a-col :span="16">
          <a-form-item
            label="姓名"
            name="name"
            :rules="[{ required: true, message: '請輸入姓名' }]"
          >
            <a-input v-model:value="data.name" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="大頭貼"
            name="avatar"
          >
            <a-upload
              v-model:file-list="avatarFileList"
              class="image-uploader"
              list-type="picture-card"
              :show-upload-list="false"
              action="/api/v1/file"
              :before-upload="beforeAvatarUpload"
              @change="handleAvatarChange"
            >
              <img v-if="previewImageUrl" :src="previewImageUrl" alt="avatar" style="width:100%;" />
              <div v-else>
                <loading-outlined v-if="avatarUploading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">選擇圖片</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item
            label="性別"
            name="gender"
            :rules="[{ required: true, message: '請選擇性別' }]"
          >
            <a-select
              v-model:value="data.gender"
              :options="genderOptions"
              size="large"
            ></a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="生日"
            name="birthdate"
            :rules="[{ required: true, message: '請輸入生日' }]"
          >
            <a-date-picker
              v-model:value="data.birthdate"
              :show-time="{ format: 'YYYY-MM-DD' }"
              format="YYYY-MM-DD"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>
        
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="聯絡電話"
            name="phone"
            :rules="[{ required: true, message: '請輸入聯絡電話' }]"
          >
            <a-input v-model:value="data.phone" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="LINE ID"
            name="line_friend_id"
            :rules="[{ required: true, message: '請輸入會員的 LINE Id' }]"
          >
            <a-input v-model:value="data.line_friend_id" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="聯絡地址"
            name="address"
            :rules="[{ required: true, message: '請輸入聯絡地址' }]"
          >
            <a-input v-model:value="data.address" size="large" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import api from '@/apis'

export default defineComponent({
  props: ['visible', 'role', 'data'],
  components: {
    PlusOutlined,
    LoadingOutlined
  },
  setup(props) {
    const genderOptions = ref([{
      value: 'male',
      label: '先生'
    }, {
      value: 'female',
      label: '女士'
    }]);

    /* Avatar Uploader */
    const avatarFileList = ref([]);
    const avatarUploading = ref(false);
    const previewImageUrl = ref('');
    const hasAvatar = ref(false);

    /* Watch */
    watch(() => props.visible, function() {
      // handle avatar
      avatarFileList.value = [];
      previewImageUrl.value = '';
      hasAvatar.value = false;

      if (props.visible && props.data.avatar) {
        previewImageUrl.value = props.data.avatar.uri;
        hasAvatar.value = true;
        console.log(hasAvatar.value)
      }

      // handle date format
      if (props.data.birthdate) {
        props.data.birthdate = dayjs(props.data.birthdate, 'YYYY-MM-DD');
      }
    });

    function _getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    const handleAvatarChange = async info => {
      console.log(info);

      if (info.file.status === 'uploading') {
        avatarUploading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        if (hasAvatar.value) {
          try {
            await deleteFile(props.data.avatar.uri);
          } catch (error) {
            console.log('Delete avatar image error. Error message: ' + error);
          }
          hasAvatar.value = false;
        }

        _getBase64(info.file.originFileObj, base64Url => {
          previewImageUrl.value = base64Url;
          avatarUploading.value = false;
        });

        // 刪除前一個上傳的 image
        if (avatarFileList.value.length > 1) {
          const firstElement = avatarFileList.value.shift();
          try {
            await deleteFile(firstElement.response.result.file_src);
          } catch (error) {
            console.log('Delete avatar image error. Error message: ' + error);
          }
        }
        
        props.data.avatar = {
          name: info.file.name,
          uri: info.file.response.result.file_src,
          size: info.file.size
        };
      }

      if (info.file.status === 'error') {
        avatarUploading.value = false;
        message.error('大頭貼照片上傳失敗，請稍後再試');
      }
    }

    const beforeAvatarUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('請選擇 jpg 或 png 的圖片格式');
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('圖片大小不可以超過 2MB');
      }

      return isJpgOrPng && isLt2M;
    }

    /* Api */
    const deleteFile = (async (code) => {
      const codeSplitList = code.split('/');
      const key = codeSplitList[codeSplitList.length-1];
      await api.v1.file.deleteOne({ key: key });
    });

    return {
      /* Form Options */
      genderOptions,

      /* Avatar Uploader */
      avatarFileList,
      avatarUploading,
      previewImageUrl,
      handleAvatarChange,
      beforeAvatarUpload
    }
  },
  computed: {
    modalLabel() {
      if (this.role == '1') return '會員'
      else if (this.role == '2') return '教練'
      else if (this.role == '3') return '助教'
    }
  },
  methods: {
    async handleOk() {
      this.$emit('confirm');
    },
    handleCancel() {
      this.$emit('cancel');
    }
  }
})
</script>