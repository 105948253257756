const user = {
  updateUser: {
    name: '',
    avatar: null,
    gender: '',
    birthdate: '',
    phone: '',
    line_friend_id: '',
    address: ''
  }
}

export default user;