import user from './user'
import coach from './coach'
import assistant from './assistant'
import admin from './admin'

const v1 = {
  user,
  coach,
  assistant,
  admin
}

export default v1;