<template>
  <a-modal
    :visible="visible"
    :title="`創建${modalLabel}`"
    :okText="`確認創建${modalLabel}`"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :span="16">
          <a-form-item
            :label="`${modalLabel}編號`"
            name="member_id"
            :rules="[{ required: true, message: `請輸入${modalLabel}編號` }]"
          >
            <a-input v-model:value="formState.member_id" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item
            :label="`${modalLabel}帳號`"
            name="identifier"
            :rules="[{ required: true, message: `請輸入${modalLabel}帳號` }]"
          >
            <a-input v-model:value="formState.identifier" size="large" />
          </a-form-item>
        </a-col>
        
        <!-- 會員：不需要設定密碼 -->
        <template v-if="role != 1">
          <a-col :span="12">
            <a-form-item
              :label="`${modalLabel}密碼`"
              name="credential"
              :rules="[{ required: true, message: `請輸入${modalLabel}密碼` }]"
            >
              <a-input v-model:value="formState.credential" size="large" />
            </a-form-item>
          </a-col>
        </template>
      </a-row>
      
      <a-row>
        <a-col :span="16">
          <a-form-item
            label="姓名"
            name="name"
            :rules="[{ required: true, message: '請輸入姓名' }]"
          >
            <a-input v-model:value="formState.name" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="大頭貼"
            name="avatar"
          >
            <a-upload
              v-model:file-list="avatarFileList"
              class="image-uploader"
              list-type="picture-card"
              accept=".png, .jpg, .jpeg"
              action="/api/v1/file"
              :show-upload-list="false"
              :max-count="1"
              :before-upload="beforeAvatarUpload"
              @change="handleAvatarChange"
            >
              <img v-if="previewImageUrl" :src="previewImageUrl" alt="avatar" style="width:100%;" />
              <div v-else>
                <loading-outlined v-if="avatarUploading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">選擇圖片</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item
            label="性別"
            name="gender"
            :rules="[{ required: true, message: '請選擇性別' }]"
          >
            <a-select
              v-model:value="formState.gender"
              :options="genderOptions"
              size="large"
            ></a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="生日"
            name="birthdate"
            :rules="[{ required: true, message: '請輸入生日' }]"
          >
            <a-date-picker
              v-model:value="formState.birthdate"
              :show-time="{ format: 'YYYY-MM-DD' }"
              format="YYYY-MM-DD"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>
        
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="聯絡電話"
            name="phone"
            :rules="[{ required: true, message: '請輸入聯絡電話' }]"
          >
            <a-input v-model:value="formState.phone" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="LINE ID"
            name="line_friend_id"
            :rules="[{ required: true, message: '請輸入會員的 LINE Id' }]"
          >
            <a-input v-model:value="formState.line_friend_id" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="聯絡地址"
            name="address"
            :rules="[{ required: true, message: '請輸入聯絡地址' }]"
          >
            <a-input v-model:value="formState.address" size="large" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import api from '@/apis'
import schema from '@/schemas'

export default defineComponent({
  props: ['visible', 'role'],
  components: {
    PlusOutlined,
    LoadingOutlined
  },
  setup() {
    /* FormState: 設定不同權限帳號的資料內容 */
    const AuthInterface = {
      member_id: '',
      role: '',
      identifier: '',
      credential: ''
    }

    const formState = ref({});
    const genderOptions = ref([{
      value: 'male',
      label: '先生'
    }, {
      value: 'female',
      label: '女士'
    }]);

    /* Avatar Uploader */
    const avatarFileList = ref([]);
    const avatarUploading = ref(false);
    const previewImageUrl = ref('');

    function _getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    const handleAvatarChange = async info => {
      if (info.file.status === 'uploading') {
        avatarUploading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        _getBase64(info.file.originFileObj, base64Url => {
          previewImageUrl.value = base64Url;
          avatarUploading.value = false;
        });

        // 刪除前一個上傳的 image
        if (avatarFileList.value.length > 1) {
          const firstElement = avatarFileList.value.shift();
          try {
            await deleteFile(firstElement.response.result.file_src);
          } catch (error) {
            console.log('Delete avatar image error. Error message: ' + error);
          }
        }
      }

      if (info.file.status === 'error') {
        avatarUploading.value = false;
        message.error('upload error');
      }
    }

    const beforeAvatarUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('請選擇 jpg 或 png 的圖片格式');
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('圖片大小不可以超過 2MB');
      }

      return isJpgOrPng && isLt2M;
    }

    /* Api */
    const deleteFile = (async (code) => {
      const codeSplitList = code.split('/');
      const key = codeSplitList[codeSplitList.length-1];
      await api.v1.file.deleteOne({ key: key });
    });

    return {
      /* Form State */
      AuthInterface,
      formState,
      genderOptions,
      
      /* Avatar Uploader */
      avatarFileList,
      avatarUploading,
      previewImageUrl,
      handleAvatarChange,
      beforeAvatarUpload,

      schema,
      api
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        let state = {};

        if (this.role == 1) {
          state = {
            ...this.AuthInterface,
            ...schema.v1.user.updateUser
          }
        }
        else if (this.role == 2) {
          state = {
            ...this.AuthInterface,
            ...schema.v1.coach.updateCoach
          }
        }
        else if (this.role == 3) {
          state = {
            ...this.AuthInterface,
            ...schema.v1.assistant.updateAssistant
          }
        }

        Object.assign(this.formState, state);
        console.log(this.formState);

        // reset image
        this.avatarFileList = [];
        this.previewImageUrl = '';
      }
    }
  },
  computed: {
    modalLabel() {
      if (this.role == '1') return '會員'
      else if (this.role == '2') return '教練'
      else if (this.role == '3') return '助教'
    }
  },
  methods: {
    async handleOk() {
      /* Check member id whether is duplicated */
      let duplicated = null;
      try {
        const checkMemberIdResp = await api.v1.auth.checkMemberId({
          member_id: this.formState.member_id
        });
        duplicated = checkMemberIdResp.data.result.duplicated;
      } catch (error) {
        console.log(error);
      }

      if (duplicated) {
        message.error('會員編號重複');
        return
      }

      /* Create Auth */
      let authId = null;
      try {
        const registerResp = await api.v1.auth.register({
          member_id: this.formState.member_id,
          role: this.role,
          identifier: this.formState.identifier,
          credential: this.formState.credential
        });
        authId = registerResp.data.result.uid;
        console.log('authId: ' + authId)
      } catch (error) {
        console.log(error);
      }
      
      /* Update Info */
      let params = {}; // init update params

      // role condition
      if (this.role == 1) { params = {...schema.v1.user.updateUser} }
      else if (this.role == 2) { params = {...schema.v1.coach.updateCoach} }
      else if (this.role == 3) { params = {...schema.v1.assistant.updateAssistant} }

      // set data
      Object.keys(params).forEach(key => {
        params[key] = this.formState[key];
      });
      console.log(params);

      // set birthdate
      if (this.formState.birthdate) {
        params.birthdate = dayjs(this.formState.birthdate).format('YYYY-MM-DD');
      }

      // set image
      if (this.avatarFileList.length > 0) {
        params.avatar = {
          name: this.avatarFileList[0].name,
          uri: this.avatarFileList[0].response.result.file_src,
          size: this.avatarFileList[0].size
        }
      }

      try {
        if (this.role == 1) await api.v1.user.updateOne(authId, params);
        else if (this.role == 2) await api.v1.coach.updateOne(authId, params);
        else if (this.role == 3) await api.v1.assistant.updateOne(authId, params);
      } catch (error) {
        console.log(error);
      }

      message.success('創建會員成功');
      this.$emit('confirm');
    },
    handleCancel() {
      this.$emit('cancel');
    }
  }
})
</script>